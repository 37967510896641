var chainConfig = {
   bsc : {
        method: 'wallet_addEthereumChain',
        params: [{
        chainId: '0x38',
        chainName: 'Binance Smart Chain',
        nativeCurrency: {
            name: 'Binance Coin',
            symbol: 'BNB',
            decimals: 18
        },
        rpcUrls: ['https://bsc-dataseed.binance.org/'],
        blockExplorerUrls: ['https://bscscan.com']
        }]
        },
        avax : {
            method: 'wallet_addEthereumChain',
            params: [{
            chainId: '0xa86a',
            chainName: 'Avalanche Network',
            nativeCurrency: {
                name: 'Avalanche Coin',
                symbol: 'AVAX',
                decimals: 18
            },
            rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
            blockExplorerUrls: ['https://snowtrace.io/']
            }]
            },
            matic : {
                method: 'wallet_addEthereumChain',
                params: [{
                chainId: '0x89',
                chainName: 'Polygon Network',
                nativeCurrency: {
                    name: 'Matic',
                    symbol: 'MATIC',
                    decimals: 18
                },
                rpcUrls: ['https://rpc-cometh-mainnet.maticvigil.com/v1/0937c004ab133135c86586b55ca212a6c9ecd224'],
                blockExplorerUrls: ['https://explorer.matic.network/']
                }]
                }
}
export default chainConfig