import React from 'react'
import { Helmet } from 'react-helmet'
import cryptoken from "../images/cryptoken.png"
import codyseum from "../images/codyseum.png"



const Seo = ({title, description, langue}) => {   
  
    return (
      <head>
        <Helmet>

      <html lang={langue} />
      <meta name="description" content={description} />
      <meta name="image" content={cryptoken} />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={window.location.pathname} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={cryptoken} />
      <meta property="og:type" content="website" />
      <meta property="og:image:alt" content="Cryptoken Logo" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Codyseum" />
      <meta name="twitter:url" content="https://twitter.com/codyseum" />
      <meta name="twitter:description" content="Welcome to Codyseum Twitter" />
      <meta name="twitter:image" content={codyseum} />
      <meta name="twitter:image:alt" content="codyseum Logo" />
      <meta name="twitter:creator" content="Codyseum" />
   
    </Helmet>     
    </head>  
    )
}
export default Seo