import { makeStyles } from '@material-ui/core'
import React from 'react'
import { Helmet } from 'react-helmet'
import Seo from "../components/Seo"

export default function Market() {

  const useStyles = makeStyles({
        home : {
            position: "relative",
            maxWidth: "100vw",
            height: "900px",
            background:"#313036"
            }
    })

    const classes = useStyles();
    return (
        <div className={classes.home}>
            <Seo title="My Cryptoken -- Crypto Market Chart" 
description="Keep an Eye on the crypto market with our crypto heat map"
langue="EN"/>
            <Helmet>
            <script src="https://quantifycrypto.com/widgets/heatmaps/js/qc-heatmap-widget.js"></script>

            </Helmet>
           
            <qc-heatmap height="550px" num-of-coins="50" currency-code="USD"></qc-heatmap>

        </div>
    )
}
