import { IconButton, Link, makeStyles, Typography } from "@material-ui/core";
import { HomeOutlined } from "@material-ui/icons";

const NotFound = () => {
  const useStyles = makeStyles({
    home: {
      position: "relative",
      maxWidth: "100vw",
      height: 1500,
      background: "#313036",
      overflow: "hidden",
    },
  });

  const classes = useStyles();

  return (
    <div className={classes.home}>
      <Typography color="textPrimary" variant="h2" gutterBottom>
        404 Page not found
      </Typography>

      <Typography color="secondary" variant="body1" gutterBottom>
        Sorry the page your are looking for doesn't exist !
      </Typography>
      <IconButton>
        <HomeOutlined
          className={classes.homeIcon}
          color="secondary"
          fontSize="large"
          onClick={(event) => (window.location.href = "/")}
        />
      </IconButton>
    </div>
  );
};

export default NotFound;
