import { AppBar,  Drawer, Grid, IconButton, Link, List, ListItem, ListItemIcon, ListItemText, makeStyles, Toolbar, Typography } from "@material-ui/core";
import { Search, AttachMoney,LocalAtm, Send, Facebook, HelpOutlineOutlined, LinkedIn, SubjectOutlined } from "@material-ui/icons";
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { useHistory, useLocation } from "react-router-dom";
import { Twitter} from "@material-ui/icons";
import codyseum from "../images/codyseumT.png"
import bsc from "../images/bsc.svg"
import matic from "../images/matic.svg"
import avax from "../images/avax.svg"
import cryptokenT from "../images/cryptokenT.png"
import LanguageSelect from "./LanguageSelect";
import '../components/I18NextConf'
import {Trans, useTranslation } from "react-i18next";
import React from "react";
import {useState} from 'react';
import {stateStore} from "./stateStore.js"
import chainConfig from "../config/chainConfig";


const drawerWidth = 240;



const useStyles = makeStyles((theme)=>{
    return {
        "@keyframes scale": {
            "0%": {
              opacity: 1,
              transform: "scale(1)"
            },
            "100%": {
              
              transform: "scale(1.05)", 
              
            }
          },
    page : {
        background:"#313036",
        width:'100%',
        padding : theme.spacing(3),
        // ['@media (max-width:810px)']: { display:"none" }
    },

    mobile : {

        background:"#313036",
        width:'100%',
        height: 1000,
        padding : theme.spacing(3),
        ['@media (min-width:810px)']: { display:"none" },
        textAlign:"center",
       
    },
    // logoMobile : {
    //     height:130,
    //     width:"auto",
    //     marginBottom:10
    // },
    // socialMobile : {
    //     position:"absolute",
    //     top : "35%",
    //     left : "30%",
    //     padding: 20,
    //     display : "flex"
    // },
    drawer:{
        position : "relative",
        width: drawerWidth,
        backgroundColor : "#27262c",
        ['@media (max-width:810px)']: { display:"none" }
    },
    footer : {
        position : "absolute",
        bottom: 5,
        left : "45%",
        zIndex : 99,
        ['@media (max-width:810px)']: { display:"none" }
    },
    appbar:{
        width:`calc(100% - ${drawerWidth}px)`,
        backgroundColor : "#27262c",
        ['@media (max-width:810px)']: { display:"none" }
    },
    drawerPaper:{
        width: drawerWidth
    },
    root : {
        display:'flex',
        position : 'relative'
    },
    active: {
        background : "#7d7a8c"
    },
    title: {
        padding: theme.spacing(2)
    },

    toolbar: theme.mixins.toolbar,
    Apptitle: {
        flexGrow: 1,
        
    },
    avatar : {
        marginLeft : theme.spacing(2)
    },

    social : {
        position: "fixed",
        bottom : 0,
        padding: 20,
        display : "flex"
    },
    social_space : {
        marginRight : 20
    },

    logoCody : {
        width:"auto",
        height:50,
        textAlign:"center",
        marginRight:"auto",
        marginLeft:"auto",
        marginTop:5,
        display:"flex",
        // animation: `$scale 3000ms infinite alternate ${theme.transitions.easing.easeInOut}`
    },

    AppLogo:{
        width:"auto",
        height : 30
        
    },
    lang:{
       marginLeft : 20

    } ,
    tButton:{
        width : 50
    }
    
}
})



const Layout = ({children}) => {

    const classes = useStyles()
    const history = useHistory();
    const location = useLocation();
    const menuItems = [
        {
            text:<Typography color="secondary"><Trans i18nKey="menu-home"/></Typography>,
            icon: <SubjectOutlined color="secondary"/>,
            path: '/'
        },
        {
            text:<Typography color="secondary"><Trans i18nKey="menu-market"/></Typography>,
            icon: <Search color="secondary"/>,
            path: '/market'
        },
        {
            text:<Typography color="secondary"><Trans i18nKey="menu-simple"/></Typography>,
            icon: <AttachMoney color="secondary"/>,
            path: '/create'
        },
    
        {
            text:<Typography color="secondary"><Trans i18nKey="menu-safemoon"/></Typography>,
            icon: <LocalAtm color="secondary"/>,
            path: '/createSafemoon'
        },
        {
            text:<Typography color="secondary"><Trans i18nKey="menu-airdrop"/></Typography>,
            icon: <Send color="secondary"/>,
            path: '/airdrop'
        },
        {
            text:<Typography color="secondary"><Trans i18nKey="menu-tutorial"/></Typography>,
            icon: <HelpOutlineOutlined color="secondary"/>,
            path: '/tutorial'
        }
    ]

   
    const chain = stateStore.useState(s => s.chain);
    const chainInfo = chainConfig[`${chain}`];    

        
        const handleChain = (event, newChain) => {
            stateStore.update(s => {
                s.chain = newChain;
              })
              const chainInfo = chainConfig[`${newChain}`];  
              window.ethereum
              .request(chainInfo)
              .catch((error) => {
                console.log(error);
              });

         
        };

    
    

    return (
              
        <div className={classes.root}>
{/* App BAR */}
<AppBar
className={classes.appbar}
elevation={0}
>
   <Toolbar>
      <Typography className={classes.Apptitle}
      variant="h5"
      color="secondary">
         My Cryptoken
      </Typography>
      <ToggleButtonGroup
            value={chain}
            exclusive
            onChange={handleChain}
            aria-label="Blockchain">
      <ToggleButton
      value="bsc" aria-label="bsc choosed" className={classes.tButton}
      >
      <img src={bsc} alt="logo bsc" className={classes.AppLogo}  />
      </ToggleButton>
     

      <ToggleButton
      value="avax" aria-label="Avax choosed" className={classes.tButton}    
      >
      <img src={avax} alt="logo Avax" className={classes.AppLogo}  />
      </ToggleButton>
      <ToggleButton
      value="matic" aria-label="matic choosed" className={classes.tButton}    
      >
      <img src={matic} alt="logo Polygon" className={classes.AppLogo}  />
      </ToggleButton>
      </ToggleButtonGroup>
     
     <div className={classes.lang}>
     <LanguageSelect />
     </div>
   </Toolbar>     

</AppBar>

{/* Drawer */}
<Drawer
className={classes.drawer}
variant = "permanent"
anchor="left"
classes = {{paper : classes.drawerPaper}}
>
<div>
<a href="https://codyseum.com">
  <img src={codyseum} alt="logo codyseum" className={classes.logoCody}  /> 
  </a>
</div>

{/* List links */}

<List >
    {menuItems.map(item => (
        <ListItem
         button 
         key={item.text}
         onClick = { () => {history.push(item.path)}}
         className={location.pathname === item.path ? classes.active : null}
         
         >
             
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text}/>
        </ListItem>
    ))}
</List>


<Grid container spacing={2} className={classes.social}>
<IconButton  >

<Twitter className={classes.social_space} color = "secondary"
onClick={event =>  window.location.href='https://twitter.com/codyseum'}/>

<LinkedIn className={classes.social_space} color = "secondary"
onClick={event =>  window.location.href='https://www.linkedin.com/company/codyseum'}/>

<Facebook className={classes.social_space} color = "secondary"
onClick={event =>  window.location.href='https://www.facebook.com/Codyseum-109322808229199'}/>
</IconButton>


    </Grid>

</Drawer>

{/* <div className={classes.mobile}>
    <Typography
     color ="textPrimary"
     variant = "h4"
     >
        <img src={cryptokenT} alt="logo cryptoken" className={classes.logoMobile}/>
    </Typography>
    <Typography
     color ="textPrimary"
     variant = "h4"
     >
     This website is built to work with metamask plugin, please visit us from a computer
    </Typography>

    <Grid container spacing={2} className={classes.socialMobile}>
<IconButton  >

<Twitter className={classes.social_space} color = "secondary"
onClick={event =>  window.location.href='https://twitter.com/codyseum'}/>

<LinkedIn className={classes.social_space} color = "secondary"
onClick={event =>  window.location.href='https://www.linkedin.com/company/codyseum'}/>

<Facebook className={classes.social_space} color = "secondary"
onClick={event =>  window.location.href='https://www.facebook.com/Codyseum-109322808229199'}/>
</IconButton>
    </Grid>

</div> */}

    <div className={classes.page}>
        <div className={classes.toolbar}></div>
        {children}
        </div>
        <Typography className={classes.footer}
variant = "h8"
color="secondary">
Made with love by <Link href="https://codyseum.com">Codyseum</Link> all rights reserved 
</Typography>
        </div> 
    
        );
}
 
export default Layout;