import React from 'react'
import { Timeline } from 'react-twitter-widgets'
export default function TwitterCard() {
    return (
        <div>

<Timeline
  dataSource={{
    sourceType: 'profile',
    screenName: 'codyseum'
  }}
  options={{
    height: '300',
    width:'400'
  }}
/>
        </div>
    )
}
