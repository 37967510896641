var Contracts = {
bsc : {
    newToken : "0x1530643aEb775d270A3d28dc90E8133F542bF33E", // 0x1530643aEb775d270A3d28dc90E8133F542bF33E mainet
    newSafemoon : "0x12992B1b0f059067344309F953b0006da4A335db",
    chainID : "0x38",
    airdrop : "0x751cA67D07AE78353F9cF84175aEc8eeB443e925",
},
avax : {
newToken : "0x1530643aEb775d270A3d28dc90E8133F542bF33E",
newSafemoon : "0x12992B1b0f059067344309F953b0006da4A335db",
chainID: "0xa86a",
airdrop : "0x6c07DAa5318932b44f73870e866cDA83a7401972"
},
matic : {
    newToken : "0x1530643aEb775d270A3d28dc90E8133F542bF33E",
    newSafemoon : "0x12992B1b0f059067344309F953b0006da4A335db",
    chainID: "0x89",
    airdrop : "0x6c07DAa5318932b44f73870e866cDA83a7401972"
    }
}

export default Contracts