import {
  Button,
  Container,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Web3 from "web3";
import newSafemoonToken from "../ABI/newSafemoonToken";
import Contracts from "../config/Contracts";
import { useState } from "react";
import Prices from "../config/Prices";
import safemoon from "../ABI/safemoon";
import Masonry from "react-masonry-css";
import Seo from "../components/Seo";
import "../components/I18NextConf";
import { Trans, useTranslation } from "react-i18next";
import {stateStore} from "../components/stateStore"
import chainConfig from "../config/chainConfig";

const useStyles = makeStyles({
  field: {
    marginTop: 16,
    marginBottom: 16,
    display: "block",
  },
  field_background: {
    background: "black",
  },

  field_label: {
    color: "#fff",
  },

  btn: {
    marginBottom: 20,
    marginTop: 20,
    display: "flex",
    color: "primary",
  },
  crd: {
    width: 420,
    display: "block",
    marginBottom: 16,
    border: "solid",
    borderColor: "primary",
    borderRadius: 16,
    padding: 20,
    backgroundColor: "#27262c",
  },
  title: {
    color: "primary",
  },
  field_label: {
    color: "secondary",
  },
});

const CreateSafemoonToken = () => {
  let web3;
  let userAccount;
  const abi = newSafemoonToken;
  const blockchain = stateStore.useState((s) => s.chain);
  const address = Contracts[`${blockchain}`].newSafemoon;
  const priceCreate = Prices[`${blockchain}`].createSafemoon;
  const priceUse = Prices[`${blockchain}`].use;
  const chainInfo = chainConfig[`${blockchain}`];
  const classes = useStyles();
  const { t } = useTranslation();

  // Create token variable
  const [name, setName] = useState("TOKEN");
  const [symbol, setSymbol] = useState("TKN");
  const [number, setNumber] = useState("100000");
  const [taxFee, setTaxFee] = useState("10");
  const [liquidityFee, setLiquidityFee] = useState("5");
  const [tokenAddress, setTokenAddress] = useState(null);
  const [tokenAddressUse, setTokenAddressUse] = useState(null);

  // Use contract Variable
  const [tokenName, setTokenName] = useState(null);
  const [tokenSymbol, setTokenSymbol] = useState(null);
  const [tokenDecimals, setTokenDecimals] = useState(null);
  const [tokenTotalSupply, setTokenTotalSupply] = useState(null);
  const [tokenTaxFee, setTokenTaxFee] = useState(null);
  const [tokenLiquidityFee, setTokenLiquidityFee] = useState(null);
  const [tokenOwner, setTokenOwner] = useState(null);

  const breakpoints = {
    default: 2,
    1100: 2,
    700: 1,
  };

  async function connect() {
    if (window.ethereum) {
      web3 = new Web3(window.ethereum);
      try {
        // Request account access if needed
        await window.ethereum.enable();
        userAccount = (await web3.eth.getAccounts())[0];
      } catch (error) {
        // User denied account access...
      }
    }
    // Legacy dapp browsers...
    else if (window.web3) {
      window.web3 = new Web3(web3.currentProvider);
      userAccount = (await web3.eth.getAccounts())[0];
    } // Non-dapp browsers...
    else {
      console.log(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }

    window.ethereum
      .request(
        chainInfo
        // method: "wallet_addEthereumChain",
        // params: [
        //   {
        //     chainId: "0x38",
        //     chainName: "Binance Smart Chain",
        //     nativeCurrency: {
        //       name: "Binance Coin",
        //       symbol: "BNB",
        //       decimals: 18,
        //     },
        //     rpcUrls: ["https://bsc-dataseed.binance.org/"],
        //     blockExplorerUrls: ["https://bscscan.com"],
        //   },
        // ],
      )
      .catch((error) => {
        console.log(error);
      });

    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: Contracts[`${blockchain}`].chainID  }], // chainId must be in hexadecimal numbers
      // 0x61 is BSC
    });
  }

  async function create() {
    connect();

    const contract = new web3.eth.Contract(abi, address);
    await contract.methods
      .createToken(
        `${name}`,
        `${symbol}`,
        `${number}`,
        `${taxFee}`,
        `${liquidityFee}`
      )
      .send({
        from: userAccount,
        value: web3.utils.toWei(`${priceCreate}`, "ether"),
      });
    const result = await contract.methods.token().call({ from: userAccount });
    console.log(result);
    setTokenAddress(result);
    setTokenAddressUse(result);
  }

  async function TokenSymbol() {
    connect();
    userAccount = (await web3.eth.getAccounts())[0];
    const contract = new web3.eth.Contract(safemoon, tokenAddressUse);
    const result = await contract.methods.symbol().call({ from: userAccount });
    setTokenSymbol(result);
  }

  async function Decimals() {
    connect();
    userAccount = (await web3.eth.getAccounts())[0];
    const contract = new web3.eth.Contract(safemoon, tokenAddressUse);
    const result = await contract.methods
      .decimals()
      .call({ from: userAccount });
    setTokenDecimals(result);
  }

  async function TokenName() {
    connect();
    userAccount = (await web3.eth.getAccounts())[0];
    const contract = new web3.eth.Contract(safemoon, tokenAddressUse);
    const result = await contract.methods.name().call({ from: userAccount });
    setTokenName(result);
  }

  async function TokenTotalSupply() {
    connect();
    userAccount = (await web3.eth.getAccounts())[0];
    const contract = new web3.eth.Contract(safemoon, tokenAddressUse);
    const result = await contract.methods
      .totalSupply()
      .call({ from: userAccount });
    setTokenTotalSupply(result);
  }

  async function SetTaxFeePercent() {
    connect();
    userAccount = (await web3.eth.getAccounts())[0];
    const contract = new web3.eth.Contract(safemoon, tokenAddressUse);
    await contract.methods.setTaxFeePercent(`${tokenTaxFee}`).send({
      from: userAccount,
      value: web3.utils.toWei(`${priceUse}`, "ether"),
    });
  }

  async function SetLiquidityPercent() {
    connect();
    userAccount = (await web3.eth.getAccounts())[0];
    const contract = new web3.eth.Contract(safemoon, tokenAddressUse);
    await contract.methods.setLiquidityFeePercent(`${tokenLiquidityFee}`).send({
      from: userAccount,
      value: web3.utils.toWei(`${priceUse}`, "ether"),
    });
  }

  async function TokenOwner() {
    connect();
    userAccount = (await web3.eth.getAccounts())[0];
    const contract = new web3.eth.Contract(safemoon, tokenAddressUse);
    const result = await contract.methods.owner().call({ from: userAccount });
    setTokenOwner(result);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    create();
  };

  const handleTokenSymbol = () => {
    TokenSymbol();
  };
  const handleDecimals = () => {
    Decimals();
  };
  const handleTokenName = () => {
    TokenName();
  };
  const handleTokenTotalSupply = () => {
    TokenTotalSupply();
  };
  const handleSetTaxFeePercent = () => {
    SetTaxFeePercent();
  };
  const handleSetLiquidityPercent = () => {
    SetLiquidityPercent();
  };
  const handleTokenOwner = () => {
    TokenOwner();
  };

  return (
    <Container>
      {/* 
*****
*********
*************
********************
Create the token interface

*******************
*************
**********
*******
*****
*/}

      <Seo
        title="My Cryptoken -- Create a Safemoon Token"
        description="Have you ever dreamed to create your own Safemoon ? it is now possible to create your crypto with us !"
        langue="EN"
      />

      <Masonry
        breakpointCols={breakpoints}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        <div className={classes.crd}>
          <Typography
            className={classes.title}
            variant="h6"
            component="h2"
            gutterBottom
            color="textPrimary"
          >
            {t("safe-create-title")}
          </Typography>
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <TextField
              onChange={(e) => setName(e.target.value)}
              className={classes.field}
              label={t("safe-create-name")}
              variant="outlined"
              color="secondary"
              InputLabelProps={{
                style: { color: "#fff" },
              }}
              inputProps={{ className: classes.field_background }}
              required
            />
            <TextField
              onChange={(e) => setSymbol(e.target.value)}
              className={classes.field}
              label={t("safe-create-symbol")}
              variant="outlined"
              color="secondary"
              required
              InputLabelProps={{
                style: { color: "#fff" },
              }}
              inputProps={{ className: classes.field_background }}
            />
            <TextField
              onChange={(e) => setNumber(e.target.value)}
              className={classes.field}
              label={t("safe-create-number")}
              variant="outlined"
              color="secondary"
              InputLabelProps={{
                style: { color: "#fff" },
              }}
              inputProps={{ className: classes.field_background }}
              required
            />

            <TextField
              onChange={(e) => setTaxFee(e.target.value)}
              className={classes.field}
              label={t("safe-create-tax")}
              variant="outlined"
              color="secondary"
              InputLabelProps={{
                style: { color: "#fff" },
              }}
              inputProps={{ className: classes.field_background }}
              required
            />

            <TextField
              onChange={(e) => setLiquidityFee(e.target.value)}
              className={classes.field}
              label={t("safe-create-liquidity")}
              variant="outlined"
              color="secondary"
              InputLabelProps={{
                style: { color: "#fff" },
              }}
              inputProps={{ className: classes.field_background }}
              required
            />

            <Button
              className={classes.btn}
              type="submit"
              color="primary"
              variant="contained"
            >
              {t("safe-create-button")} {name}{" "}
            </Button>
          </form>

          <div>
            {tokenAddress ? (
              <Typography color="textPrimary">
                Congratulation you have created {number} {name} ({symbol}) at
                the {tokenAddress} address, with {liquidityFee} % Liquidity fees
                and {taxFee} % Tax fee.
              </Typography>
            ) : (
              <div />
            )}
          </div>
        </div>

        {/* 
*****
*********
*************
********************
Use the contract you just created 

*******************
*************
**********
*******
*****
*/}

        <div className={classes.crd}>
          <Typography
            className={classes.title}
            variant="h6"
            component="h2"
            gutterBottom
            color="textPrimary"
          >
            {t("safe-use-title")}
          </Typography>

          <TextField
            onChange={(e) => setTokenAddressUse(e.target.value)}
            className={classes.field}
            label={t("safe-use-address")}
            variant="outlined"
            color="secondary"
            value={tokenAddressUse}
            fullWidth
            required
            InputLabelProps={{
              style: { color: "#fff" },
            }}
            inputProps={{ className: classes.field_background }}
          />

          <TextField
            onChange={(e) => setTokenTaxFee(e.target.value)}
            className={classes.field}
            label={t("safe-use-tax")}
            variant="outlined"
            color="secondary"
            InputLabelProps={{
              style: { color: "#fff" },
            }}
            inputProps={{ className: classes.field_background }}
          />
          <Button
            className={classes.btn}
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleSetTaxFeePercent}
          >
            {t("safe-use-taxB")}{" "}
          </Button>

          <TextField
            onChange={(e) => setTokenLiquidityFee(e.target.value)}
            className={classes.field}
            label={t("safe-use-liquidity")}
            variant="outlined"
            color="secondary"
            InputLabelProps={{
              style: { color: "#fff" },
            }}
            inputProps={{ className: classes.field_background }}
          />
          <Button
            className={classes.btn}
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleSetLiquidityPercent}
          >
            {t("safe-use-liquidityB")}{" "}
          </Button>

          <Button
            className={classes.btn}
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleTokenName}
          >
            {t("safe-use-nameC")}
          </Button>
          <div>
            {name ? (
              <Typography color="textPrimary">{tokenName}</Typography>
            ) : (
              <div />
            )}
          </div>

          <Button
            className={classes.btn}
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleDecimals}
          >
            {t("safe-use-decimalsC")}
          </Button>
          <div>
            {tokenDecimals ? (
              <Typography color="textPrimary">{tokenDecimals}</Typography>
            ) : (
              <div />
            )}
          </div>
          <Button
            className={classes.btn}
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleTokenOwner}
          >
            {t("safe-use-ownerC")}
          </Button>
          <div>
            {tokenOwner ? (
              <Typography color="textPrimary">{tokenOwner}</Typography>
            ) : (
              <div />
            )}
          </div>

          <Button
            className={classes.btn}
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleTokenSymbol}
          >
            {t("safe-use-symbolC")}
          </Button>
          <div>
            {tokenSymbol ? (
              <Typography color="textPrimary">{tokenSymbol}</Typography>
            ) : (
              <div />
            )}
          </div>

          <Button
            className={classes.btn}
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleTokenTotalSupply}
          >
            {t("safe-use-supplyC")}
          </Button>
          <div>
            {tokenTotalSupply ? (
              <Typography color="textPrimary">
                {tokenTotalSupply / 10 ** 9}
              </Typography>
            ) : (
              <div />
            )}
          </div>
        </div>
      </Masonry>
    </Container>
  );
};

export default CreateSafemoonToken;
