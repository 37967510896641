import { Container, Grid, IconButton, Typography } from "@material-ui/core";
import * as styles from "../styles/home.module.css";
import cryptoken from "../images/cryptoken.png";
import TwitterCard from "../components/TwitterCard";
import { Helmet } from "react-helmet";
import { Facebook, LinkedIn, Twitter } from "@material-ui/icons";
import Seo from "../components/Seo";
import "../components/I18NextConf";
import { Trans, useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();

  return (
    <Container className={styles.home}>
      <Seo
        title="My Cryptoken -- Create your crypto and make airdrop"
        description="Have you ever dreamed to create your own Crypto and to make Airdrops ? it is now easy to create your crypto and airdrop with us !"
        langue="EN"
      />

      <Helmet>
        {/* <script src="https://quantifycrypto.com/widgets/grid/js/qc-grid-widget.js"></script> */}
        <script defer src="https://www.livecoinwatch.com/static/lcw-widget.js"></script>
      </Helmet>

      <Typography variant="h4" color="textPrimary" component="h1" className={styles.title}>
        <Trans i18nKey="home-title" />
      </Typography>

      <Typography variant="h4" color="textPrimary" component="h1" className={styles.titleMobile}>
        Unfortunatly our website require metamask, please visit us from a computer
      </Typography>

      <div className={styles.body1}>
        <Typography variant="body1" color="secondary">
          <Trans i18nKey="home-subtitle" />
          <br />

          <hr color="#aa8929" width="400px"></hr>
        </Typography>
      </div>
      <img src={cryptoken} alt="Logo Cryptoken" className={styles.logoHome1} />
      <img src={cryptoken} alt="Logo Cryptoken" className={styles.logoHome2} />

      <div className={styles.market1}>
      <div class="livecoinwatch-widget-6" lcw-coin="BTC" lcw-base="USD" lcw-period="d" lcw-color-tx="#ffffff" lcw-color-bg="#1f2434" lcw-border-w="1" ></div>
 <div class="livecoinwatch-widget-6" lcw-coin="ETH" lcw-base="USD" lcw-period="d" lcw-color-tx="#ffffff" lcw-color-bg="#1f2434" lcw-border-w="1" ></div>
 <div class="livecoinwatch-widget-6" lcw-coin="BNB" lcw-base="USD" lcw-period="d" lcw-color-tx="#ffffff" lcw-color-bg="#1f2434" lcw-border-w="1" ></div>
 <div class="livecoinwatch-widget-6" lcw-coin="MATIC" lcw-base="USD" lcw-period="d" lcw-color-tx="#ffffff" lcw-color-bg="#1f2434" lcw-border-w="1" ></div>
 <div class="livecoinwatch-widget-6" lcw-coin="AVAX" lcw-base="USD" lcw-period="d" lcw-color-tx="#ffffff" lcw-color-bg="#1f2434" lcw-border-w="1" ></div>
 <div class="livecoinwatch-widget-6" lcw-coin="ADA" lcw-base="USD" lcw-period="d" lcw-color-tx="#ffffff" lcw-color-bg="#1f2434" lcw-border-w="1" ></div>
 <div class="livecoinwatch-widget-6" lcw-coin="DOGE" lcw-base="USD" lcw-period="d" lcw-color-tx="#ffffff" lcw-color-bg="#1f2434" lcw-border-w="1" ></div>
 <div class="livecoinwatch-widget-6" lcw-coin="SHIB" lcw-base="USD" lcw-period="d" lcw-color-tx="#ffffff" lcw-color-bg="#1f2434" lcw-border-w="1" ></div>

        {/* <qc-grid-widget
          theme="dark"
          width="385px"
          ranking-type="top_20"
          currency-code="USD"
        ></qc-grid-widget> */}
      </div>

      <div className={styles.body2}>
        <Typography variant="body1" color="secondary">
          <Trans i18nKey="home-body1" />
        </Typography>

        <div className={styles.news}>
          <iframe
            scrolling="yes"
            allowtransparency="true"
            src="https://cryptopanic.com/widgets/news/?bg_color=27262C&amp;font_family=sans&amp;header_text_color=FFFFFF&amp;link_color=FFFFFF&amp;news_feed=trending&amp;posts_limit=20&amp;text_color=AA8929&amp;title=Trending%20News"
            width="100%"
            height="300px"
            frameborder="0"
          ></iframe>
        </div>
      </div>
      <div className={styles.barre}>
        <hr color="#aa8929" width="400px"></hr>
      </div>
      <div className={styles.twitterCard}>
        <TwitterCard />
      </div>

      <div className={styles.body3}>
        <Typography variant="body1" color="secondary">
          <Trans i18nKey="home-body2" />
        </Typography>
      </div>

      <Grid container className={styles.social}>
        <IconButton>
          <Twitter
            className={styles.social_icon}
            color="secondary"
            fontSize="large"
            onClick={(event) =>
              (window.location.href = "https://twitter.com/codyseum")
            }
          />
        </IconButton>

        <IconButton>
          <LinkedIn
            className={styles.social_icon}
            color="secondary"
            fontSize="large"
            onClick={(event) =>
              (window.location.href =
                "https://www.linkedin.com/company/codyseum")
            }
          />
        </IconButton>

        <IconButton>
          <Facebook
            className={styles.social_icon}
            color="secondary"
            fontSize="large"
            onClick={(event) =>
              (window.location.href =
                "https://www.facebook.com/Codyseum-109322808229199")
            }
          />
        </IconButton>
      </Grid>
    </Container>
  );
};

export default Home;
