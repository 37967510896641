import {createTheme, ThemeProvider} from '@material-ui/core'
import Layout from './components/Layout';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import CreateSimpleToken from './pages/CreateSimpleToken';
import CreateSafemoonToken from './pages/CreateSafemoonToken';
import Home from './pages/Home';
import Tutorial from './pages/Tutorial';
import Market from './pages/Market';
import Airdrop from './pages/Airdrop';
import NotFound from './pages/NotFound'
import Test from './pages/Test'

const theme = createTheme({
  palette : {
    primary : {
      main : "#aa8929"
    },
    secondary :{
      main : "#ffffff"
    },
    background : {
      paper : "#27262c",
      default : "#27262c"
    },

    text : {
      primary : "#348ef2"
    },
    root : {
      "& .MuiFormLabel-root" : {
        color : "#fefefe"
      }
    }
  
  },
  
   typography : {
     fontFamily : 'Quicksand',
     fontWeightLight : 400,
     fontWeightRegular : 500,
     fontWeightMedium: 600,
     fontWeightBold : 700
   }
  
  });


function App() {
  return (
   
    <ThemeProvider theme={theme}>
    <Router>
      <Layout>
      <Switch>
        <Route exact path="/">
          <Home/>
        </Route>
        <Route path="/market">
          <Market/>
        </Route>
        <Route path="/create">
          <CreateSimpleToken/>
        </Route>
        <Route path="/createSafemoon">
          <CreateSafemoonToken/>
        </Route>
        <Route path="/airdrop">
          <Airdrop/>
        </Route>
        <Route path="/tutorial">
          <Tutorial/>
        </Route>
        {/* <Route path="/test">
          <Test/>
        </Route> */}
        <Route path="*">
              <NotFound />
            </Route>
      </Switch>
      </Layout>
    </Router>
    </ThemeProvider>
  
  );
}

export default App;
