var Prices = {
  bsc : {
    createSimple: "0.6",
    createSafemoon: "0.7",
    use: "0.1",
    airdrop: "0.2",
  },
  avax : {
    createSimple: "3.9",
    createSafemoon: "4.6",
    use: "0.65",
    airdrop: "1.3",
  },
  matic : {
    createSimple: "174",
    createSafemoon: "200",
    use: "28",
    airdrop: "57",
  }

};

export default Prices;
