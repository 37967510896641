import {
  Button,
  Container,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Web3 from "web3";
import newToken from "../ABI/newToken";
import Contracts from "../config/Contracts";
import { useState } from "react";
import simpleToken from "../ABI/simpletoken";
import Prices from "../config/Prices";
import chainConfig from "../config/chainConfig";
import Masonry from "react-masonry-css";
import Seo from "../components/Seo";
import "../components/I18NextConf";
import { Trans, useTranslation } from "react-i18next";

import { stateStore } from "../components/stateStore";

const useStyles = makeStyles({
  field: {
    marginTop: 16,
    marginBottom: 16,
    display: "block",
  },
  field_background: {
    background: "black",
  },

  field_label: {
    color: "#fff",
  },

  btn: {
    marginBottom: 20,
    marginTop: 20,
    display: "flex",
    color: "primary",
  },
  crd: {
    width: 420,
    display: "block",
    marginBottom: 16,
    border: "solid",
    borderColor: "primary",
    borderRadius: 16,
    padding: 20,
    backgroundColor: "#27262c",
  },
  title: {
    color: "primary",
  },
  field_label: {
    color: "secondary",
  },
});

const CreateSimpleToken = () => {
  let web3;
  let userAccount;
  const blockchain = stateStore.useState((s) => s.chain);
  const address = Contracts[`${blockchain}`].newToken;
  const priceCreate = Prices[`${blockchain}`].createSimple;
  const priceUse = Prices[`${blockchain}`].use;
  const chainInfo = chainConfig[`${blockchain}`];
  const classes = useStyles();
  const [name, setName] = useState("TOKEN");
  const [symbol, setSymbol] = useState("TKN");
  const [number, setNumber] = useState("100000");
  const [tokenAddress, setTokenAddress] = useState(null);
  const [tokenAddressUse, setTokenAddressUse] = useState(null);
  const [owner, setOwner] = useState(null);
  const [burn, setBurn] = useState(null);
  const [mint, setMint] = useState(null);
  const [decimals, setDecimals] = useState(null);
  const [tokenName, setTokenName] = useState(null);
  const [tokenOwner, setTokenOwner] = useState(null);
  const [tokenSymbol, setTokenSymbol] = useState(null);
  const { t } = useTranslation();

  const breakpoints = {
    default: 2,
    1100: 2,
    700: 1,
  };

  async function connect() {
    if (window.ethereum) {
      web3 = new Web3(window.ethereum);
      try {
        // Request account access if needed
        await window.ethereum.enable();
        userAccount = (await web3.eth.getAccounts())[0];
      } catch (error) {
        // User denied account access...
      }
    }
    // Legacy dapp browsers...
    else if (window.web3) {
      window.web3 = new Web3(web3.currentProvider);
      userAccount = (await web3.eth.getAccounts())[0];
    } // Non-dapp browsers...
    else {
      console.log(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }

   await window.ethereum
      .request(
        chainInfo
        // {
        // method: 'wallet_addEthereumChain',
        // params: [{
        // chainId: '0x38',
        // chainName: 'Binance Smart Chain',
        // nativeCurrency: {
        //     name: 'Binance Coin',
        //     symbol: 'BNB',
        //     decimals: 18
        // },
        // rpcUrls: ['https://bsc-dataseed.binance.org/'],
        // blockExplorerUrls: ['https://bscscan.com']
        // }]
        // }
      )
      .catch((error) => {
        console.log(error);
      });

    // change network
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: Contracts[`${blockchain}`].chainID }], // chainId must be in hexadecimal numbers
    });
  }

  async function create() {
    console.log("the choosen blocken is " + blockchain);
    console.log("new token address is " + address);
    console.log("the price to create a simple token is " + priceCreate);
    console.log("the price to use the contract is " + priceUse);
    console.log(chainInfo);

    connect();
    userAccount = (await web3.eth.getAccounts())[0];

    const contract = new web3.eth.Contract(newToken, address);
    await contract.methods
      .createToken(`${name}`, `${symbol}`, `${number}`)
      .send({
        from: userAccount,
        value: web3.utils.toWei(`${priceCreate}`, "ether"),
      });
    const result = await contract.methods.token().call({ from: userAccount });
    console.log(result);
    setTokenAddress(result);
    setTokenAddressUse(result);
  }

  async function Mint() {
    connect();
    const BN = web3.utils.toBN;
    let useMint = new BN(mint * 10 ** 18);
    console.log("bumber to mint : " + useMint);
    userAccount = (await web3.eth.getAccounts())[0];
    const contract = new web3.eth.Contract(simpleToken, tokenAddressUse);
    await contract.methods
      .mint(useMint)
      .send({
        from: userAccount,
        value: web3.utils.toWei(`${priceUse}`, "ether"),
      });
  }

  async function Burn() {
    connect();
    const BN = web3.utils.toBN;
    let useBurn = new BN(burn * 10 ** 18);
    userAccount = (await web3.eth.getAccounts())[0];
    const contract = new web3.eth.Contract(simpleToken, tokenAddressUse);
    await contract.methods
      .burn(useBurn)
      .send({
        from: userAccount,
        value: web3.utils.toWei(`${priceUse}`, "ether"),
      });
  }
  async function Owner() {
    connect();
    userAccount = (await web3.eth.getAccounts())[0];
    const contract = new web3.eth.Contract(simpleToken, tokenAddressUse);
    await contract.methods
      .transferOwnership(`${owner}`)
      .send({
        from: userAccount,
        value: web3.utils.toWei(`${priceUse}`, "ether"),
      });
  }

  async function TokenOwner() {
    connect();
    userAccount = (await web3.eth.getAccounts())[0];
    const contract = new web3.eth.Contract(simpleToken, tokenAddressUse);
    const result = await contract.methods.owner().call({ from: userAccount });
    setTokenOwner(result);
  }

  async function TokenSymbol() {
    connect();
    userAccount = (await web3.eth.getAccounts())[0];
    const contract = new web3.eth.Contract(simpleToken, tokenAddressUse);
    const result = await contract.methods.symbol().call({ from: userAccount });
    setTokenSymbol(result);
  }

  async function Decimals() {
    connect();
    userAccount = (await web3.eth.getAccounts())[0];
    const contract = new web3.eth.Contract(simpleToken, tokenAddressUse);
    const result = await contract.methods
      .decimals()
      .call({ from: userAccount });
    setDecimals(result);
  }

  async function TokenName() {
    connect();
    userAccount = (await web3.eth.getAccounts())[0];
    const contract = new web3.eth.Contract(simpleToken, tokenAddressUse);
    const result = await contract.methods.name().call({ from: userAccount });
    setTokenName(result);
  }

  const handleSubmitCreate = (e) => {
    e.preventDefault();
    create();
  };

  const handleMint = () => {
    Mint();
  };
  const handleBurn = () => {
    Burn();
  };
  const handleOwner = () => {
    Owner();
  };
  const handleTokenOwner = () => {
    TokenOwner();
  };
  const handleTokenSymbol = () => {
    TokenSymbol();
  };
  const handleDecimals = () => {
    Decimals();
  };
  const handleTokenName = () => {
    TokenName();
  };

  return (
    <Container>
      {/* 
*****
*********
*************
********************
Create the token interface

*******************
*************
**********
*******
*****
*/}

      <Seo
        title="My Cryptoken -- Create a Crypto Token"
        description="Have you ever dreamed to create your own Crypto ? it is now easy to create your crypto with us !"
        langue="EN"
      />

      <Masonry
        breakpointCols={breakpoints}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        <div className={classes.crd}>
          <Typography
            color="textPrimary"
            variant="h6"
            component="h2"
            gutterBottom
          >
            <Trans i18nKey="simple-create-title" />
          </Typography>
          <form noValidate autoComplete="off" onSubmit={handleSubmitCreate}>
            <TextField
              onChange={(e) => setName(e.target.value)}
              className={classes.field}
              label={t("simple-create-name")}
              variant="outlined"
              color="secondary"
              InputLabelProps={{
                style: { color: "#fff" },
              }}
              inputProps={{ className: classes.field_background }}
              required
            />

            <TextField
              onChange={(e) => setSymbol(e.target.value)}
              className={classes.field}
              label={t("simple-create-symbol")}
              variant="outlined"
              color="secondary"
              InputLabelProps={{
                style: { color: "#fff" },
              }}
              inputProps={{ className: classes.field_background }}
              required
            />

            <TextField
              onChange={(e) => setNumber(e.target.value)}
              className={classes.field}
              label={t("simple-create-number")}
              variant="outlined"
              color="secondary"
              InputLabelProps={{
                style: { color: "#fff" },
              }}
              inputProps={{ className: classes.field_background }}
              required
            />

            <Button
              className={classes.btn}
              type="submit"
              color="primary"
              variant="contained"
            >
              {t("simple-create-button")} {name}{" "}
            </Button>
          </form>

          <div>
            {tokenAddress ? (
              <Typography color="textPrimary">
                Congratulation you have created {number} {name} ({symbol}) at
                the {tokenAddress} address.
              </Typography>
            ) : (
              <div />
            )}
          </div>
        </div>

        {/* 
*****
*********
*************
********************
Use the contract you just created 

*******************
*************
**********
*******
*****
*/}

        <div className={classes.crd}>
          <Typography
            color="textPrimary"
            variant="h6"
            component="h2"
            gutterBottom
          >
            {t("simple-use-title")}
          </Typography>

          <TextField
            onChange={(e) => setTokenAddressUse(e.target.value)}
            className={classes.field}
            label={t("simple-use-address")}
            variant="outlined"
            color="secondary"
            InputLabelProps={{
              style: { color: "#fff" },
            }}
            inputProps={{ className: classes.field_background }}
            value={tokenAddressUse}
            fullWidth
            required
          />

          <TextField
            onChange={(e) => setMint(e.target.value)}
            className={classes.field}
            label={t("simple-use-mint")}
            variant="outlined"
            color="secondary"
            InputLabelProps={{
              style: { color: "#fff" },
            }}
            inputProps={{ className: classes.field_background }}
          />
          <Button
            className={classes.btn}
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleMint}
          >
            {t("simple-use-mintB")}{" "}
          </Button>

          <TextField
            onChange={(e) => setBurn(e.target.value)}
            className={classes.field}
            label={t("simple-use-burn")}
            variant="outlined"
            color="secondary"
            InputLabelProps={{
              style: { color: "#fff" },
            }}
            inputProps={{ className: classes.field_background }}
          />

          <Button
            className={classes.btn}
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleBurn}
          >
            {t("simple-use-burnB")}{" "}
          </Button>

          <TextField
            onChange={(e) => setOwner(e.target.value)}
            className={classes.field}
            label={t("simple-use-owner")}
            variant="outlined"
            color="secondary"
            InputLabelProps={{
              style: { color: "#fff" },
            }}
            inputProps={{ className: classes.field_background }}
          />
          <Button
            className={classes.btn}
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleOwner}
          >
            {t("simple-use-ownerB")}{" "}
          </Button>

          <Button
            className={classes.btn}
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleTokenName}
          >
            {t("simple-use-nameC")}
          </Button>
          <div>
            {name ? (
              <Typography color="textPrimary">{tokenName}</Typography>
            ) : (
              <div />
            )}
          </div>

          <Button
            className={classes.btn}
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleDecimals}
          >
            {t("simple-use-decimalsC")}
          </Button>
          <div>
            {decimals ? (
              <Typography color="textPrimary">{decimals}</Typography>
            ) : (
              <div />
            )}
          </div>
          <Button
            className={classes.btn}
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleTokenOwner}
          >
            {t("simple-use-ownerC")}
          </Button>
          <div>
            {tokenOwner ? (
              <Typography color="textPrimary">{tokenOwner}</Typography>
            ) : (
              <div />
            )}
          </div>

          <Button
            className={classes.btn}
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleTokenSymbol}
          >
            {t("simple-use-symbolC")}
          </Button>
          <div>
            {tokenSymbol ? (
              <Typography color="textPrimary">{tokenSymbol}</Typography>
            ) : (
              <div />
            )}
          </div>
        </div>
      </Masonry>
    </Container>
  );
};

export default CreateSimpleToken;
