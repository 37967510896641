import React, { useEffect, useState } from "react";
import Contracts from "../config/Contracts";
import Prices from "../config/Prices";
import addressesAirdropBSC from "../config/addressesAirdropBSC.js";
import { stateStore } from "../components/stateStore";
import Web3 from "web3";
import {
  Button,
  Container,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import airdropToken from "../ABI/airdropToken";
import simpleToken from "../ABI/simpletoken";
import chainConfig from "../config/chainConfig";
import Masonry from "react-masonry-css";
import Seo from "../components/Seo";
import "../components/I18NextConf";
import { Trans, useTranslation } from "react-i18next";

export default function Airdrop() {
  // ----
  // ----------
  // -------------------        Styles
  // -----------
  // ---------
  // ------
  const useStyles = makeStyles({
    home: {
      position: "relative",
      maxWidth: "100vw",
      height: "900px",
      background: "#313036",
    },

    field: {
      marginTop: 16,
      marginBottom: 16,
      display: "block",
    },
    field_background: {
      background: "black",
    },

    field_label: {
      color: "#fff",
    },

    btn: {
      marginBottom: 20,
      marginTop: 20,
      display: "flex",
      color: "primary",
    },
    crd: {
      width: 420,
      display: "block",
      marginBottom: 16,
      border: "solid",
      borderColor: "primary",
      borderRadius: 16,
      padding: 20,
      backgroundColor: "#27262c",
    },

    crdPosition: {
      position: "absolute",
      top: "00%",
      left: "50%",
    },

    title: {
      color: "primary",
    },
    field_label: {
      color: "secondary",
    },
  });

  // ----
  // ----------
  // -------------------        variables
  // -----------
  // ---------
  // ------

  let web3;
  let userAccount;
  const blockchain = stateStore.useState((s) => s.chain);
  const classes = useStyles();
  const address = Contracts[`${blockchain}`].airdrop;
  const priceAirdrop = Prices[`${blockchain}`].airdrop;
  const chainInfo = chainConfig[`${blockchain}`];
  const [tokenAddress, setTokenAddress] = useState(
    "0x329FdD950fe25264d650cE78a5D7c58608642944"
  );
  const [tokenNumber, setTokenNumber] = useState("1");
  const [tokenTotal, setTokenTotal] = useState("100");
  const [addressNumber, setAddressNumber] = useState("800");
  const [addressList, setAddressList] = useState([]);
  const [displayError, setDisplayError] = useState(null);
  const [approveToken, setApproveToken] = useState(0);
  const [decimals, setDecimals] = useState("18");
  const [displayApproval, setDisplayApproval] = useState(false);
  const { t } = useTranslation();

  const breakpoints = {
    default: 2,
    1100: 2,
    700: 1,
  };

  // ----
  // ----------
  // -------------------        web3js
  // -----------
  // ---------
  // ------

  async function connect() {
    if (window.ethereum) {
      web3 = new Web3(window.ethereum);
      try {
        // Request account access if needed
        await window.ethereum.enable();
        userAccount = (await web3.eth.getAccounts())[0];
      } catch (error) {
        // User denied account access...
      }
    }
    // Legacy dapp browsers...
    else if (window.web3) {
      window.web3 = new Web3(web3.currentProvider);
      userAccount = (await web3.eth.getAccounts())[0];
    } // Non-dapp browsers...
    else {
      console.log(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }

    window.ethereum.request(chainInfo);

    // change network
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: Contracts[`${blockchain}`].chainID }], // chainId must be in hexadecimal numbers
    });
  }

  // ----
  // ----------
  // -------------------        functions
  // -----------
  // ---------
  // ------

  async function Decimals() {
    await connect();
    userAccount = (await web3.eth.getAccounts())[0];
    const contract = new web3.eth.Contract(simpleToken, tokenAddress);
    const result = await contract.methods
      .decimals()
      .call({ from: userAccount });
    setDecimals(result);
    console.log("decimals is : " + result);
  }

  const allowance = async () => {
    await connect();
    const BN = web3.utils.toBN;
    userAccount = (await web3.eth.getAccounts())[0];
    const contract = new web3.eth.Contract(simpleToken, tokenAddress);
    const result = new BN(await contract.methods
      .allowance(userAccount, address)
      .call({ from: userAccount }));
    console.log("number of approved token : " + result);
    await setApproveToken(result);
  };

  const approve = async () => {
    setDisplayApproval(true);
    await connect();
    const BN = web3.utils.toBN;
    let utilTokenTotal = new BN(tokenTotal * 10 ** decimals);
    userAccount = (await web3.eth.getAccounts())[0];
    const contract = new web3.eth.Contract(simpleToken, tokenAddress);
    await contract.methods
      .approve(address, utilTokenTotal)
      .send({ from: userAccount });
    await allowance();
    setDisplayApproval(false);
  };

  async function sendAirdrop() {
    if (approveToken >= tokenTotal * 10 ** decimals) {
      const BN = web3.utils.toBN;
      let utiltokenNumber = new BN(
        (tokenTotal * 10 ** decimals) / addressNumber
      );

      console.log("utiltokenNumber : " + utiltokenNumber);

      let a = Math.floor(Math.random() * (addressesAirdropBSC.length + 1));
      if (a < addressNumber) {
        a = addressNumber;
      }
      console.log("random number is " + a);
      let utilAddressList = addressesAirdropBSC.slice(a - addressNumber, a);

      console.log("utilAddressList : " + utilAddressList);

      userAccount = (await web3.eth.getAccounts())[0];
      const contract = new web3.eth.Contract(airdropToken, address);
      await contract.methods
        .airdrop(utilAddressList, utiltokenNumber, `${tokenAddress}`)
        .send({
          from: userAccount,
          gasLimit: addressNumber * 40000,
          value: web3.utils.toWei(`${priceAirdrop}`, "ether"),
        });
    } else {
      approve();
    }
  }
  // ----
  // ----------
  // -------------------        USE EFFECT
  // -----------
  // ---------
  // ------

  useEffect(() => {
    setDisplayApproval(false);
    allowance();
    Decimals();
  }, [tokenAddress]);

  // ----
  // ----------
  // -------------------        Handles
  // -----------
  // ---------
  // ------

  const handleSubmitAirdrop = (e) => {
    e.preventDefault();
    if (addressNumber <= 800) {
      allowance();
      sendAirdrop();
      setDisplayError(null);
    } else {
      setDisplayError(
        "800 addresses is the limitation by transaction, you can call it several times, it will be sent to differents addresses"
      );
    }
  };

  // ----
  // ----------
  // -------------------        UseAddressAirdropBSC
  // -----------
  // ---------
  // ------

  // ----
  // ----------
  // -------------------        RENDER
  // -----------
  // ---------
  // ------

  return (
    <Container className={classes.home}>
      <Seo
        title="My Cryptoken -- Make an Airdrop"
        description="Do you want to Airdrop a crypto token ? My Cryptoken is here to help you to airdop your crypto token to a lot of people"
        langue="EN"
      />
      <Masonry
        breakpointCols={breakpoints}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        <div className={classes.crd}>
          <Typography
            color="textPrimary"
            variant="h6"
            component="h2"
            gutterBottom
          >
            {t("airdrop-title")}
          </Typography>
          <form noValidate autoComplete="off" onSubmit={handleSubmitAirdrop}>
            <TextField
              onChange={(e) => setTokenAddress(e.target.value)}
              className={classes.field}
              label={t("airdrop-address")}
              variant="outlined"
              color="secondary"
              InputLabelProps={{
                style: { color: "#fff" },
              }}
              inputProps={{ className: classes.field_background }}
              required
            />

            <TextField
              onChange={(e) => setTokenTotal(e.target.value)}
              className={classes.field}
              label={t("airdrop-total")}
              variant="outlined"
              color="secondary"
              value={tokenTotal}
              InputLabelProps={{
                style: { color: "#fff" },
              }}
              inputProps={{ className: classes.field_background }}
              required
            />

            <TextField
              //  onChange = { (e) => (setTokenNumber(e.target.value)) }
              className={classes.field}
              value={tokenTotal / addressNumber}
              label={t("airdrop-each")}
              variant="outlined"
              color="secondary"
              //   disabled = "true"
              InputLabelProps={{
                style: { color: "#fff" },
              }}
              inputProps={{ className: classes.field_background }}
            />

            <TextField
              onChange={(e) => setAddressNumber(e.target.value)}
              className={classes.field}
              label={t("airdrop-number")}
              variant="outlined"
              color="secondary"
              defaultValue="800"
              InputLabelProps={{
                style: { color: "#fff" },
              }}
              inputProps={{ className: classes.field_background }}
              required
            />

            <div>
              {displayError ? (
                <Typography color="textPrimary">{displayError}</Typography>
              ) : (
                <div />
              )}
            </div>

            <div>
              {displayApproval && tokenAddress ? (
                <Button
                  className={classes.btn}
                  color="primary"
                  variant="contained"
                  disabled
                >
                  Waiting for approval
                </Button>
              ) : (
                <Button
                  className={classes.btn}
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  {t("airdrop-send")}{" "}
                </Button>
              )}
            </div>
          </form>

          <div className={classes.crdPosition}>
            <div className={classes.crd}>
              <Typography
                color="textPrimary"
                variant="h6"
                component="h2"
                gutterBottom
              >
                {t("airdrop-title2")}
              </Typography>

              <Typography color="secondary" variant="body1" gutterBottom>
                {t("airdrop-tuto")}
              </Typography>
            </div>
          </div>
        </div>
      </Masonry>
    </Container>
  );
}
