import {
  List,
  makeStyles,
  Typography,
  IconButton,
  ButtonGroup,
  ListItemText,
  Grid,
} from "@material-ui/core";
import { Facebook, LinkedIn, Twitter } from "@material-ui/icons";
import bsc from "../images/bsc.svg";
import matic from "../images/matic.svg"
import avax from "../images/avax.svg"
import Seo from "../components/Seo";
import chainConfig from "../config/chainConfig";
import Web3 from "web3";
import "../components/I18NextConf";
import { Trans, useTranslation } from "react-i18next";

const useStyles = makeStyles({
  title: {
    padding: 20,
    position: "relative",
    marginLeft: "auto",
    marginRight: "auto",
  },
  root: {
    position: "relative",
    maxWidth: "100vw",
    height: 1500,
    background: "#313036",
  },

  body1: {
    padding: 20,
  },
  bscIcon: {
    display: "flex",
    marginRight: "auto",
    marginLeft: "auto",
  },
  bsc: {
    width: 30,
  },
  meta : {
 display : "flex",
 marginLeft : "auto",
 marginRight : "auto"
  },
  social: {
    position: "absolute",
    width: 400,
    bottom: "10%",
    left: "30%",
  },

  social_icon: {
    width: 80,
  },
});


let web3;
let userAccount;

const AddChain = async (chainInfo) => {
  if (window.ethereum) {
    web3 = new Web3(window.ethereum);
    try {
      // Request account access if needed
      await window.ethereum.enable();
      userAccount = (await web3.eth.getAccounts())[0];
    } catch (error) {
      // User denied account access...
    }
  }
  // Legacy dapp browsers...
  else if (window.web3) {
    window.web3 = new Web3(web3.currentProvider);
    userAccount = (await web3.eth.getAccounts())[0];
  } // Non-dapp browsers...
  else {
    console.log(
      "Non-Ethereum browser detected. You should consider trying MetaMask!"
    );
  }

  window.ethereum
    .request(chainInfo)
    .catch((error) => {
      console.log(error);
    });
};

const Tutorial = () => {
  const classes = useStyles();

  const handleBSC = () => {
    AddChain(chainConfig.bsc)

  }
  const handleMATIC = () => {
    AddChain(chainConfig.matic)
    
  }
  const handleAVAX = () => {
    AddChain(chainConfig.avax)
  }


  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Seo
        title="My Cryptoken -- Tutorial"
        description="Lear how to create your own cryptocurrency or token and how to airdrop it."
        langue="EN"
      />
      <Typography className={classes.title} color="textPrimary" variant="h4">
        <Trans i18nKey="tutorial-title" />
      </Typography>
      <Typography className={classes.body1} color="secondary" variant="body1">
        {t("tutorial-1")}
      </Typography>
      <Typography className={classes.body1} color="secondary" variant="body1">
        {t("tutorial-2")}
      </Typography>
      <ButtonGroup className={classes.meta}>
        <IconButton className={classes.bscIcon}>
          <img
            src={bsc}
            alt="logo bsc"
            className={classes.bsc}
            onClick={handleBSC}
          />
        </IconButton>
        <IconButton className={classes.bscIcon}>
          <img
            src={matic}
            alt="logo matic"
            className={classes.bsc}
            onClick={handleMATIC}
          />
        </IconButton>
        <IconButton className={classes.bscIcon}>
          <img
            src={avax}
            alt="logo avax"
            className={classes.bsc}
            onClick={handleAVAX}
          />
        </IconButton>
      </ButtonGroup>

      <Typography className={classes.body1} color="secondary" variant="body1">
        {t("tutorial-3")}
      </Typography>

      <Typography className={classes.body1} color="secondary" variant="body1">
        {t("tutorial-4")}
        <List>
          <ListItemText> {t("tutorial-5")}</ListItemText>
          <ListItemText> {t("tutorial-6")}</ListItemText>
        </List>
      </Typography>
      <Typography className={classes.body1} color="secondary" variant="body1">
        <strong> {t("tutorial-7")}</strong>
        <List>
          <ListItemText> {t("tutorial-8")}</ListItemText>
          <ListItemText> {t("tutorial-9")}</ListItemText>
          <ListItemText> {t("tutorial-10")}</ListItemText>
          <ListItemText>{t("tutorial-11")} </ListItemText>
          <ListItemText>{t("tutorial-12")}</ListItemText>
          <ListItemText>{t("tutorial-13")}</ListItemText>
          <ListItemText>{t("tutorial-14")}</ListItemText>
        </List>
      </Typography>

      <Typography className={classes.body1} color="secondary" variant="body1">
        <Trans i18nKey="tutorial-15" />
        <List>
          <ListItemText>{t("tutorial-16")}</ListItemText>
          <ListItemText>{t("tutorial-17")}</ListItemText>
          <ListItemText>{t("tutorial-18")}</ListItemText>
          <ListItemText>{t("tutorial-19")}</ListItemText>
          <ListItemText>{t("tutorial-20")}</ListItemText>
        </List>
      </Typography>

      <Typography className={classes.title} color="textPrimary" variant="h6">
        {t("tutorial-21")}
      </Typography>
      <Grid container className={classes.social}>
        <IconButton>
          <Twitter
            className={classes.social_icon}
            color="secondary"
            fontSize="large"
            onClick={(event) =>
              (window.location.href = "https://twitter.com/codyseum")
            }
          />
        </IconButton>

        <IconButton>
          <LinkedIn
            className={classes.social_icon}
            color="secondary"
            fontSize="large"
            onClick={(event) =>
              (window.location.href =
                "https://www.linkedin.com/company/codyseum")
            }
          />
        </IconButton>

        <IconButton>
          <Facebook
            className={classes.social_icon}
            color="secondary"
            fontSize="large"
            onClick={(event) =>
              (window.location.href =
                "https://www.facebook.com/Codyseum-109322808229199")
            }
          />
        </IconButton>
      </Grid>
    </div>
  );
};

export default Tutorial;
